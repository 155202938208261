<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="modelValue"
      width="290px"
      persistent
    >
      <template #activator="{ on }">
        <validation-provider
          ref="provider"
          v-slot="{ errors }"
          :events="events"
          :name="fieldName"
          :rules="extendedRules"
          tag="div"
        >
          <v-text-field
            :id="fieldName"
            ref="dateField"
            v-model="formattedValue"
            v-bind="$attrs"
            :error-messages="errors"
            :name="fieldName"
            :required="required"
            type="text"
            readonly
            v-on="on"
            @blur="$eventLogger.blurEvent"
            @click="$eventLogger.clickEvent"
            @focus="$eventLogger.focusEvent"
          />
        </validation-provider>
      </template>
      <v-date-picker
        v-model="modelValue"
        :locale="locale"
        :type="type"
        first-day-of-week="1"
        actions
        scrollable
      >
        <v-card-actions>
          <v-spacer/>
          <v-btn
            :id="`${fieldName}--cancel`"
            color="primary"
            text
            @click="
              modal = false;
              logOnClick($event, modelValue);
            "
          >
            <translate>
              Cancel
            </translate>
          </v-btn>
          <v-btn
            :id="`${fieldName}--confirm`"
            color="primary"
            text
            @click.native="
              setDate();
              logOnClick($event, modelValue);
            "
          >
            <translate>
              OK
            </translate>
          </v-btn>
        </v-card-actions>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwDatePicker',

  components: {
    'validation-provider': ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    events: {
      type: [String, Array],
      default: 'change',
    },

    rules: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'date',
    },

    // must be included in props
    value: {
      type: null,
      required: true,
    },
  },

  data: () => ({
    formattedValue: null,
    modal: false,
    modelValue: '',
  }),

  computed: {
    ...mapGetters({
      apiErrors: 'application/apiErrors',
      locale: 'application/locale',
      initialData: 'application/getInitialData',
    }),

    extendedRules() {
      const delimiter = !this.rules ? '' : '|';
      return `${this.rules}${delimiter}error:${this.apiErrors.indexOf(this.fieldName) >= 0}`;
    },

    fieldName() {
      return this.$attrs.id;
    },

    initialValue() {
      return this.initialData[this.fieldName] || this.value;
    },

    preventSetData() {
      return Object.keys(this.$attrs).includes('prevent-set-data');
    },

    required() {
      return this.rules.split('|').includes('required');
    },
  },

  watch: {
    // Handles internal model changes.
    modelValue(newVal) {
      this.$emit('input', newVal);
    },

    // Handles external model changes.
    value(newVal) {
      this.modelValue = newVal;
    },
  },

  created() {
    this.setInitialValue();
  },

  methods: {
    ...mapActions({
      setData: 'application/setData',
      removeApiError: 'application/removeApiError',
    }),

    getElementData(value) {
      return {
        targetId: this.fieldName,
        name: this.fieldName,
        nodeName: 'INPUT',
        type: 'date',
        value: String(value),
      };
    },

    logOnChange(value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent({ type: 'change' }, data);
    },

    logOnClick(event, value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent(event, data);
    },

    async onChange(validate) {
      this.removeApiError(this.fieldName);

      await this.$nextTick();
      const { valid } = await this.validate(validate);

      if (!valid || this.preventSetData) return;

      await this.submit();

      // Validation after HTTP request is required so that
      // potential API errors are rendered in UI
      await this.validate(validate);
    },

    // set the selected date and close the dialog
    async setDate() {
      await this.$nextTick();
      this.modelValue = this.modelValue.padEnd(10, '-01');
      this.$refs.dialog.save(this.modelValue);

      await this.onChange();

      this.formattedValue = this.$filters.date(this.modelValue, {
        type: this.type,
        locale: this.locale,
      });

      this.logOnChange(this.modelValue);
    },

    setInitialValue() {
      this.modelValue = this.initialValue;
      this.formattedValue = this.$filters.date(this.initialValue, {
        type: this.type,
        locale: this.locale,
      });
    },

    async submit() {
      const params = {
        [this.fieldName]: this.modelValue,
      };

      await this.setData(params);
    },

    async validate() {
      const response = await this.$refs.provider.validate();

      this.$emit('validate', response);

      return response;
    },
  },
};
</script>
